@font-face {
    font-family: 'WorkSans';
    src: local('WorkSans'), url("./../fonts/WorkSans-Light.ttf");
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url("./../fonts/avenir.otf");
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url("./../fonts/OpenSans-Light.ttf");
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url("./../fonts/Poppins-Light.ttf");
}

body {
  overflow: hidden;
  color: #656565 !important;
  font-size: 12px !important;
}

body.worksans {
  font-family: WorkSans, sans-serif !important;
}

body.avenir {
  font-family: Avenir, sans-serif !important;
}

body.opensans {
  font-family: OpenSans, sans-serif !important;
}

body.poppins {
  font-family: Poppins, sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

li {
  list-style: none;
  display: inline;
}

li+li:before {
  content: "|";
  padding: 25px;
  padding-left: 15px;
  padding-right: 15px;
}

.header {
  top: 0;
  z-index: 10000;
}

.header-items {
  justify-content: space-between;
  align-items: center;
}

#offline {
  background-color: #4c4c4c;
  display: flex;
  font-size: 0.8rem;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 0;
  padding: 1px;
  transition: height 0.5s ease-in-out; 
}

#offline.offline {
  height: 20px;
}

#offline.online {
  height: 0;
  padding: 0;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.green {
  color: #008000;
  margin-right: 25px;
}

.footer {
  position: fixed;
  bottom: 5px;
  z-index: 10000;
}

.footer .MuiGrid-item {
  display: flex !important;
  justify-content: center !important;
}

.content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
}

.content .MuiGrid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content .MuiGrid-item div {
  text-align: center;
}

img {
  max-height: 40px;
  width: auto;
}

img.logo-traces {
  max-height: 120px;
}

.content h1 {
  text-align: center;
  font-weight: bold !important;
  margin: 0;
  padding: 0;
  margin-top: 50px;
}

.content #article {
  margin-top: 50px;
  font-size: 14px;
}

.content a {
  text-decoration: underline;
}

.content .MuiLinearProgress-root {
  margin-top: 75px;
  height: 10px !important;
  border-radius: 15px !important;
}

.content .MuiLinearProgress-bar {
  border-radius: 15px !important;
}

.content .MuiLinearProgress-colorPrimary {
    background-color: #eeeeee !important; 
}
