@media (max-width: 675px) { 
  body {
    font-size: 10px !important;
  }

  .header .MuiGrid-item {
    display: flex !important;
    justify-content: center !important;
  }

  img {
    max-height: 30px;
    width: auto;
  }

  img.logo-traces {
    max-height: 85px;
  }

  .content h1 {
    font-size: 22px;
    margin-top: 25px;
  }

  .content #article {
    margin-top: 25px;
    font-size: 12px;
  }

  .content .MuiLinearProgress-root {
    margin-top: 50px;
    height: 7px !important;
    border-radius: 15px !important;
  }
}

@media (max-width: 450px) { 
  img {
    max-height: 25px;
    width: auto;
  }

  img.logo-traces {
    max-height: 75px;
  }

  .content h1 {
    font-size: 17px;
  }  

  .content #article {
    font-size: 11px;
  }

  .content .MuiLinearProgress-root {
    margin-top: 50px;
    height: 7px !important;
    border-radius: 15px !important;
  }

  .footer li {
    display: block;
    text-align: center;
  }

  .footer li+li:before {
    display: none;
  }
}
